<template>
  <b-modal
    id="blog-create-modal"
    title="Blog Create Modal"
    centered
    hide-footer
    size="lg"
    @hidden="reset"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
  >
    <template #modal-title>
      <h2 class="m-0">Blog Create</h2>
    </template>
    <validation-observer ref="blogCreateFormValidation">
      <b-form @submit.prevent="validationForm">
        <b-form-group label-for="blog_category">
          <template #label>
            Blog Category <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Category"
            rules="required"
          >
            <v-select
              v-model="blogCategory"
              placeholder="Select Blog Category"
              :options="blogCategories"
              label="name"
              value="id"
              clearable
            ></v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="intro">
          <template #label>
            Introduction <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="intro"
            v-model="intro"
            placeholder="Introduction"
            required
            @keydown="checkMaxLength($event, 'intro', 400)"
          />
        </b-form-group>

        <b-form-group label-for="description">
          <template #label> Description </template>
          <quill-editor v-model="description" />
          <b-form-group label-for="status">
            <template #label>
              Status <span class="text-danger">*</span>
            </template>
            <b-form-select
              id="status"
              v-model="status"
              :options="options"
              required
            ></b-form-select>
          </b-form-group>
        </b-form-group>
        <validation-provider v-slot="{ errors }" name="Image" rules="required">
          <b-form-group
            class="form-group"
            id="input-group-2"
            label-for="input-2"
            description="Upload an image in PNG, JPG, or JPEG format."
            :invalid-feedback="errors[0]"
          >
            <template #label>
              Image <span class="text-danger">*</span>
            </template>
            <b-form-file
              ref="imageInput"
              id="documents"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              required
              plain
              accept=".png,.jpg,.jpeg"
              v-model="image"
              @change="validateFileType($event)"
            ></b-form-file>
          </b-form-group>
        </validation-provider>

        <b-form-group class="mt-2 text-right">
          <b-button type="submit" variant="primary" pill class="mr-1">
            Save
          </b-button>
          <b-button
            type="reset"
            variant="outline-secondary"
            @click="reset"
            pill
          >
            Reset
          </b-button>
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormFile,
} from "bootstrap-vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormFile,
  },
  data() {
    return {
      required,
      blogCategory: null,
      blogCategories: [],
      intro: "",
      image: null,
      description: "",
      status: "",
      file: [],
      regex: /^[a-zA-Z ]*$/,
      options: [
        { value: "", text: "-- Select --" },
        { value: true, text: "Enable" },
        { value: false, text: "Disable" },
      ],
    };
  },
  methods: {
    ...mapActions({
      createBlog: "appData/createBlog",
      getBlogCategories: "appData/getBlogCategoryPublic",
    }),
    checkMaxLength(event, fieldName, maxLength) {
      if (
        event.target.value.length >= maxLength &&
        event.key !== "Backspace" &&
        event.key !== "Delete"
      ) {
        event.preventDefault();
        this[fieldName] = event.target.value.slice(0, maxLength);
      }
    },
    async validationForm() {
      const success = await this.$refs.blogCreateFormValidation.validate();
      if (success) {
        await this.submit();
      }
    },

    validateFileType(event) {
      const file = event.target.files[0];
      if (!file) return;

      const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (!allowedTypes.includes(file.type)) {
        event.target.value = null;
        if (this.$refs.imageInput) {
          this.$refs.imageInput.$el.setCustomValidity(
            "Please upload a PNG, JPG, or JPEG image file."
          );
          this.$refs.imageInput.$el.reportValidity();
        }
      } else {
        if (this.$refs.imageInput) {
          this.$refs.imageInput.$el.setCustomValidity("");
        }
      }
    },
    async submit() {
      try {
        const formData = new FormData();
        formData.append("category", this.blogCategory.id);
        formData.append("intro", this.intro);
        formData.append("image", this.image);
        formData.append("description", this.description);
        formData.append("status", this.status);
        formData.append("created_by", this.getLoggedInUser.id);
        formData.append("updated_by", this.getLoggedInUser.id);
        const res = await this.createBlog(formData);
        if (res.status === 200) {
          this.$swal({
            title: "Blog created successfully",
            icon: "success",
          });
          this.reset();
          this.$nextTick(() => {
            this.$bvModal.hide("blog-create-modal");
          });
          this.$emit("modalClosed");
        }
      } catch (error) {
        this.displayError(error);
      }
    },
    reset() {
      this.blogCategory = null;
      this.intro = "";
      this.image = null;
      this.description = "";
      this.status = "";
    },
  },
  computed: {
    ...mapGetters({ getLoggedInUser: "appData/getLoggedInUser" }),
  },
  async mounted() {
    const res_blogCategories = await this.getBlogCategories();
    this.blogCategories = res_blogCategories.data;
  },
};
</script>

<style></style>
